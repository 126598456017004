
































































































import { Component, Vue } from 'vue-property-decorator'
import router from '@/router'
import { getCounter, getQrCode } from '@/api/index'
import moment from 'moment'
import momentTimezone from 'moment-timezone'
import { AppModule } from '@/store/modules/app'
import { clipboardSuccess } from '@/utils/clipboard'
import { currencyType } from '@/utils/constant'
import { setLanguage } from '@/utils/cookies'

@Component({
  name: 'Thai-QR-pc',
  components: {}
})
export default class extends Vue {
  public clipboardSuccess = clipboardSuccess
  public loading: boolean = true
  public expireTime: string = ''
  public orderInfo: any = {
    amount: '',
    bank: [],
    is_expired: false,
    ordernumber: '',
    status: 0,
    extra_param: {
      qrcode_text: ''
    }
  }
  public config: any = {}
  public configdata: any = {}
  public time: any = '00:00'
  public timer: any = null
  public poll: any = null
  public currencyType: any = currencyType
  public retryCount: number = 3
  public videoUrl: any = ''
  public qrcodeWatermark: any = ''

  // 视频演示播放
  public videoShow: boolean = false
  public videoPlay() {
    this.videoShow = true
  }

  public closePlay() {
    this.videoShow = false
  }

  // 获取二维码
  public qrCode = {
    url: ''
  }
  public getQrcode = async () => {
    await getQrCode().then((res: any) => {
      this.qrCode.url = `data:image/png;base64,${res.qrcode}`
    })
  }

  public async getCounter() {
    try {
      await getCounter().then((res: any) => {
        this.orderInfo = res
        this.videoUrl = this.orderInfo.video_url
        if (!AppModule.chatInfo) {
          this.config = res.extra_param.config
          this.configdata = res.extra_param
          this.qrcodeWatermark = res.extra_param.config.qrcode_watermark

          AppModule.SetChatInfo(res.extra_param.config)
          // AppModule.SetChatInfo(res.extra_param)
        }

        const time = res.extra_param.expire_time
          ? res.extra_param.expire_time > res.expire_time
            ? res.expire_time
            : res.extra_param.expire_time
          : res.expire_time

        const timezone =
          this.$route.query['language'] === 'vi' ||
          this.$route.query['language'] === 'th' ||
          this.$route.query['language'] === 'id'
            ? 'Asia/Bangkok'
            : ''

        this.expireTime = timezone
          ? momentTimezone
              .tz(time * 1000, timezone)
              .format('YYYY-MM-DD HH:mm:ss')
          : moment(time * 1000).format('YYYY-MM-DD HH:mm:ss')

        if (!res.is_expired && res.status === 0) {
          if (!this.poll) {
            this.poll = window.setInterval(() => {
              this.getCounter()
            }, 5000)

            const diffTime = res.current_time * 1000 - Date.now()
            this.timer = window.setInterval(() => {
              let start = momentTimezone.tz(Date.now() + diffTime, timezone)
              let end = momentTimezone.tz(time * 1000, timezone)
              let diff = end.diff(start)
              let minutes: any = moment.duration(diff).minutes()
              minutes = minutes < 10 ? '0' + minutes : minutes
              let seconds: any = moment.duration(diff).seconds()
              seconds = seconds < 10 ? '0' + seconds : seconds

              if (diff > 0) {
                this.time = minutes + ':' + seconds
              } else {
                window.clearInterval(this.timer)
                window.clearInterval(this.poll)
                router.replace({ name: 'error' })
              }
            }, 1000)
          }
        } else if (res.is_expired) {
          if (this.poll) {
            window.clearInterval(this.poll)
            window.clearInterval(this.timer)
          }
          router.replace({ name: 'error' })
        } else if (res.status === 1) {
          if (this.poll) {
            window.clearInterval(this.poll)
            window.clearInterval(this.timer)
          }
          router.replace({ name: 'successEn' })
        }

        this.loading = false
      })
    } catch (e) {
      if (!this.poll) {
        if (this.retryCount-- > 0) {
          this.getCounter()
        } else {
          this.$confirm(`Do you want to try again?`, 'The Request Timeout')
            .then(() => {
              this.retryCount = 3
              this.getCounter()
            })
            .catch(() => {
              router.replace({ name: 'error' })
            })
        }
      }
      throw new Error(`${this.$route.path}Counter接口出错`)
    }
  }

  created() {
    this.getQrcode()
    this.getCounter()
    setLanguage('en')
  }

  destroyed() {
    if (this.poll) {
      window.clearInterval(this.poll)
      this.poll = null
    }
    if (this.timer) {
      window.clearInterval(this.timer)
      this.timer = null
    }
  }
}
